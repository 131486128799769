import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'

function Obrigado() {
  const [redirect, setRedirect] = useState()

  useEffect(() => {
    var url = window.location.href
    var arr = url.split('/')

    setRedirect(`${arr[0]}//${arr[2]}/masterinternational/`)
  }, [])

  return (
    <Layout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{ color: '#ae9962', marginTop: 100, fontSize: 52 }}>
          Obrigado. Seu contato foi recebido.
        </h1>
        <a style={{ color: '#ae9962' }} href={redirect}>
          Voltar à página inicial
        </a>
      </div>
    </Layout>
  )
}
export default Obrigado
